const fieldTypes = [
  { label: "Tekst", value: "text" },
  { label: "Pole wyboru", value: "select" },
  { label: "Ocena", value: "rating" },
  { label: "Liczba", value: "number" },
  { label: "Zdjęcie", value: "photo" },
  { label: "Tak/Nie", value: "bool" },
  { label: "Nagłówek", value: "sectionName" }
];

export default fieldTypes;
