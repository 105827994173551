import React, { Component } from "react";
import fieldTypes from "../../constants/templateFieldTypes";

import {
  Button,
  TextInput,
  SelectInput,
  MultiselectInput,
  Toggle,
  StepRules
} from "../common/";
import TopBar from "../topBar/TopBar.redux";
import Icon from "@mdi/react";
import {
  mdiPlus,
  mdiCursorMove,
  mdiDeleteForever,
  mdiPanVertical
} from "@mdi/js";
import StepRulesModal from "./StepRulesModal.redux";

const isDescendant = (parent, child) => {
  let node = child.parentNode;
  while (node != null) {
    if (node == parent) {
      return true;
    }
    node = node.parentNode;
  }
  return false;
};

class TemplateItem extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showTopDragIndicator: false,
      showBottomDragIndicator: false
    };
    this.handleInputChange = this.handleInputChange.bind(this);
    this.save = this.save.bind(this);
    this.cancel = this.cancel.bind(this);
    this.isDraggedByHandle = false;
    this.inOutCounter = 0;
  }

  handleInputChange(path, event) {
    this.props.changeInputValue("currentTemplate", event, path);
  }

  async save() {
    await this.props.saveTemplateItem();
    this.props.history.push("/templates");
  }

  cancel() {
    this.props.history.push("/templates");
  }

  onDragStart(e) {
    if (this.isDraggedByHandle) {
      e.dataTransfer.setData("draggedTemplateItemId", this.props.stepId);
    } else {
      e.preventDefault();
    }
  }
  checkDragHandle(e) {
    this.isDraggedByHandle = isDescendant(this.dragHandle, e.target);
  }

  onDragLeave() {
    this.inOutCounter -= 1;
    if (this.inOutCounter === 0) {
      this.setState({
        showBottomDragIndicator: false,
        showTopDragIndicator: false
      });
    }
  }

  render() {
    const {
      uiState,
      stepId,
      stepNo,
      ratedReport,
      pointsPerSection
    } = this.props;
    const { steps } = uiState;

    const item = steps[stepId];
    const onChange = this.handleInputChange.bind(this, `steps.${stepId}`);
    const displayRequiredOption =
      ratedReport && item.type && item.type.value === "bool";
    const displayHQPhotoOption = item.type && item.type.value === "photo";
    const isSectionName = item.type && item.type.value === "sectionName";
    const showSelectOptions = item.type && item.type.value === "select";
    const displayRateInputs =
      ratedReport &&
      item.type &&
      ["bool", "number", "rating"].includes(item.type.value);

    const displayPointsPerSectionFactor = isSectionName && pointsPerSection;
    return (
      <div
        ref={ref => {
          this.container = ref;
        }}
        onDragEnter={e => {
          this.inOutCounter += 1;
        }}
        onDrop={e => {
          let place = this.state.showTopDragIndicator ? "before" : "after";
          this.props.moveTemplateStep(
            e.dataTransfer.getData("draggedTemplateItemId"),
            stepId,
            place
          );
          this.onDragLeave();
        }}
        onDragOver={e => {
          if (this.inOutCounter) {
            const rect = this.container.getBoundingClientRect();
            const scrollTop =
              document.documentElement.scrollTop || document.body.scrollTop;
            const divideValue =
              scrollTop + rect.y + Math.round(rect.height / 2);
            const showBottomIndicator = e.pageY > divideValue;
            if (showBottomIndicator && !this.state.showBottomDragIndicator) {
              this.setState({
                showBottomDragIndicator: true,
                showTopDragIndicator: false
              });
            } else if (
              !showBottomIndicator &&
              !this.state.showTopDragIndicator
            ) {
              this.setState({
                showTopDragIndicator: true,
                showBottomDragIndicator: false
              });
            }
          }
          e.preventDefault();
        }}
        onDragLeave={this.onDragLeave.bind(this)}
      >
        <div
          className={`c-dragIndicator c-dragIndicator--top ${
            this.state.showTopDragIndicator ? "" : "c-dragIndicator--disabled"
          }`}
        />
        <div
          className="c-panel c-panel--topBar"
          key={stepId}
          draggable={true}
          onDragStart={this.onDragStart.bind(this)}
          onMouseDown={this.checkDragHandle.bind(this)}
        >
          <SelectInput
            options={fieldTypes}
            value={item.type}
            onChange={onChange}
            label="Typ pola"
            id="type"
          />

          <TextInput
            id="name"
            value={item.name}
            onChange={onChange}
            type="text"
            label="Nazwa"
          />
          <TextInput
            id="comment"
            value={item.comment}
            onChange={onChange}
            type="text"
            label="Komentarz"
          />
          {showSelectOptions && (
            <TextInput
              id="selectOptions"
              value={item.selectOptions}
              onChange={onChange}
              type="text"
              label="Opcje do wyboru"
            />
          )}
          {!isSectionName ? (
            <Toggle
              id="required"
              checked={item.required}
              onChange={onChange}
              label={"Pole wymagane"}
            />
          ) : null}
          {displayPointsPerSectionFactor ? (
            <TextInput
              id="factor"
              value={item.factor}
              onChange={onChange}
              type="number"
              label="Waga punktów w sekcji"
            />
          ) : null}
          <StepRules
            id="stepRules"
            stepId={stepId}
            stepNo={stepNo}
            rules={item.displayRules || []}
            onChange={() => {}}
            label={"Pokaż gdy"}
          />
          {displayHQPhotoOption ? (
            <Toggle
              id="hqPhotos"
              checked={item.hqPhotos}
              onChange={onChange}
              label={"Zdjęcia wysokiej jakości"}
            />
          ) : null}
          {displayRequiredOption ? (
            <Toggle
              id="critical"
              checked={item.critical}
              onChange={onChange}
              label={"Wymagane do zaliczenia"}
            />
          ) : null}
          {displayRateInputs ? (
            <div className="stepOption">
              <span className="stepOption__label">Ocena</span>{" "}
              <Button
                modifiers={["purple", "small"]}
                onClick={() => {
                  this.props.startScoreEdit(stepId);
                  this.props.showModal("STEP_SCORE");
                }}
              >
                Zmień
              </Button>
            </div>
          ) : null}

          <div className="c-currentTemplate__actionBar">
            <div>
              <div
                className="c-currentTemplate__actionBarButton c-draggable"
                ref={ref => {
                  this.dragHandle = ref;
                }}
              >
                <Icon
                  path={mdiPanVertical}
                  className="c-currentTemplate__actionBarButtonIcon"
                  size="25px"
                />
              </div>
              <span className="c-currentTemplate__panelTitle">
                Krok #{stepNo + 1}
              </span>
            </div>

            <div
              className="c-currentTemplate__actionBarButton"
              onClick={() => {
                this.props.deleteTemplateItem(stepId);
              }}
            >
              <Icon
                path={mdiDeleteForever}
                className="c-currentTemplate__actionBarButtonIcon"
                size="25px"
              />
            </div>
          </div>
        </div>
        <div
          className={`c-dragIndicator ${
            this.state.showBottomDragIndicator
              ? ""
              : "c-dragIndicator--disabled"
          }`}
        />
      </div>
    );
  }
}

export default TemplateItem;
