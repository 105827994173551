import React, { Component } from "react";
import { InputNumber } from "antd";

export default class TextStep extends Component {
  render() {
    return (
      <>
        <InputNumber
          onChange={this.props.onChangeText}
          value={this.props.value}
        />
      </>
    );
  }
}
