import * as AT from "../constants/actionTypes";
import { createPromiseAction } from "../utils/misc";
import * as Templates from "../api/templates";
import { changeViewLoadingState } from "./uiState";
import { getAuthorQuestion } from "../constants/authorQuestion";

const formatTemplateFromBackend = template => {
  const formattedTemplate = { ...template };
  formattedTemplate.steps = template.steps.map(step => {
    if (!(step.type && step.type.value === "select")) return step;
    return { ...step, selectOptions: step.selectOptions.join(";") };
  });
  return formattedTemplate;
};

export const getTemplates = createPromiseAction(
  Templates.get,
  (result, args, dispatch) => {
    const templates = result.data.map(template =>
      formatTemplateFromBackend(template)
    );

    dispatch({ type: AT.GET_TEMPLATES_SUCCESS, payload: templates });
    dispatch(calculateVisibleTemplates());
    dispatch(changeViewLoadingState("templates", false));
  },
  null,
  dispatch => {
    dispatch(changeViewLoadingState("templates", true));
  }
);

const formatStep = rawStep => {
  if (!(rawStep.type && rawStep.type.value === "select")) return rawStep;
  const step = { ...rawStep };
  delete step.selectOptions;
  step.selectOptions = rawStep.selectOptions
    .split(";")
    .map(item => item.trim())
    .filter(item => item !== "");
  return step;
};

export const saveCurrentTemplate = () => async (dispatch, getState) => {
  const { uiState } = getState().reducer;
  const { currentTemplate } = uiState;
  const { info, steps, stepsOrder, assignedEmployees } = currentTemplate;

  const template = {
    _id: currentTemplate._id,
    info,
    steps: stepsOrder.map(i => formatStep(steps[i])),
    assignedEmployees: (assignedEmployees || []).map(e => e.value)
  };
  if (currentTemplate._id) {
    dispatch(updateTemplate(template));
  } else {
    dispatch(addNewTemplate(template));
  }
};

export const addNewTemplate = createPromiseAction(
  Templates.add,
  (result, args, dispatch) => {
    dispatch({ type: AT.ADD_TEMPLATE_SUCCESS, payload: result.data });
    dispatch(calculateVisibleTemplates());
  }
);
export const deleteTemplate = createPromiseAction(
  Templates.deleteOne,
  (result, args, dispatch) => {
    dispatch({ type: AT.DELETE_TEMPLATE_SUCCESS, payload: args[0] });
    dispatch(calculateVisibleTemplates());
  }
);

export const updateTemplate = createPromiseAction(
  Templates.update,
  (result, args, dispatch) => {
    dispatch({ type: AT.UPDATE_TEMPLATE_SUCCESS, payload: result.data });
    dispatch(calculateVisibleTemplates());
  }
);

export const calculateVisibleTemplates = () => (dispatch, getState) => {
  const { employees, templates } = getState().reducer;
  const items = Object.values(templates.data).map(item => ({
    id: item._id,
    name: item.info.name,
    stepsNo: item.stepsOrder.length,
    assignedEmployees: item.assignedEmployees.map(e => {
      let emp = employees.data[e];
      return emp ? `${emp.last_name} - ${emp.first_name}` : "";
    })
  }));
  const { searchBox } = templates;
  let visible = items
    .filter(
      item =>
        !searchBox ||
        JSON.stringify(item)
          .toLocaleLowerCase()
          .includes(searchBox.toLocaleLowerCase())
    )
    .map(item => item.id);
  dispatch({
    type: AT.UPDATE_VISIBLE_ELEMENTS,
    payload: { visible, view: "templates" }
  });
};

export const sortTemplates = sortKey => (dispatch, getState) => {
  const { sorting, data, order } = getState().reducer.templates;
  let newSorting = { ...sorting };
  if (newSorting.key === sortKey) {
    newSorting.decr = !newSorting.decr;
  } else {
    newSorting = { key: sortKey, decr: false };
  }
  let newOrder = Object.values(data)
    .sort((a, b) => {
      let multi = newSorting.decr ? -1 : 1;
      switch (newSorting.key) {
        case "stepNo": {
          return a.stepsOrder.length > b.stepsOrder.length ? multi : -1 * multi;
        }
        default: {
          return a[sortKey] > b[sortKey] ? multi : -1 * multi;
        }
      }
    })
    .map(i => i._id);
  dispatch({
    type: AT.UPDATE_ELEMENTS_ORDER,
    payload: {
      view: "templates",
      order: newOrder,
      sorting: newSorting
    }
  });
};

export const startTemplateEdit = (id, duplicate = false) => async (
  dispatch,
  getState
) => {
  const { templates, employees } = getState().reducer;
  const templateToEdit = { ...templates.data[id] };
  templateToEdit.assignedEmployees = templateToEdit.assignedEmployees.map(e => {
    const employee = employees.data[e];
    if (employee) {
      return {
        label: `${employee.first_name} ${employee.last_name}`,
        value: e
      };
    } else {
      return { label: "Usunięty pracownik", value: e };
    }
  });
  if (duplicate) {
    delete templateToEdit._id;
    templateToEdit.info = {
      ...templateToEdit.info,
      name: templateToEdit.info.name + " - kopia"
    };
  }
  dispatch({ type: AT.START_TEMPLATE_EDIT, payload: templateToEdit, id });
};

export const moveTemplateStep = (stepToMoveId, anchorStepId, place) => ({
  type: AT.MOVE_TEMPLATE_STEP,
  payload: { stepToMoveId, anchorStepId, place }
});

export const changeRuleValue = (ruleNo, key, value) => ({
  type: AT.CHANGE_RULE_VALUE,
  payload: { ruleNo, key, value }
});

export const initNewRule = () => ({ type: AT.INIT_NEW_RULE });

export const deleteRule = ruleNo => ({ type: AT.DELETE_RULE, payload: ruleNo });

export const saveRules = () => (dispatch, getState) => {
  const { rules, stepId } = getState().reducer.uiState.stepRulesModal;
  dispatch({ type: AT.SAVE_RULES_FOR_STEP, payload: { rules, stepId } });
};

export const startRulesEdit = stepId => (dispatch, getState) => {
  const { steps, stepsOrder } = getState().reducer.uiState.currentTemplate;
  const stepIndex = stepsOrder.findIndex(s => s === stepId);
  dispatch({
    type: AT.START_RULES_EDIT,
    payload: {
      stepId,
      rules: steps[stepId].displayRules,
      stepNo: stepIndex + 1
    }
  });
};

export const importTemplateFromFile = () => (dispatch, getState) => {
  return new Promise((resolve, reject) => {
    const { file } = getState().reducer.uiState.importTemplate;
    let data = new FormData();
    data.append("file", file);
    Templates.importFile(data)
      .then(result => {
        dispatch({ type: AT.ADD_TEMPLATE_SUCCESS, payload: result.data });
        dispatch(calculateVisibleTemplates());
        resolve(result.data);
      })
      .catch(err => {
        reject(err);
      });
  });
};

export const startScoreEdit = stepId => (dispatch, getState) => {
  const { steps, stepsOrder } = getState().reducer.uiState.currentTemplate;
  const stepIndex = stepsOrder.findIndex(s => s === stepId);
  dispatch({
    type: AT.START_SCORE_EDIT,
    payload: {
      stepId,
      rules: steps[stepId].scoreRules,
      stepNo: stepIndex + 1
    }
  });
};

export const changeScoreRuleValue = (ruleNo, key, value) => ({
  type: AT.CHANGE_SCORE_RULE_VALUE,
  payload: { ruleNo, key, value }
});

export const getAvailableTemplates = createPromiseAction(
  Templates.getAvailableTemplates,
  (result, args, dispatch) => {
    const templates = result.data.map(template => {
      if (template.info.requireAuthorName) {
        return {
          ...template,
          steps: [getAuthorQuestion(), ...template.steps]
        };
      }
      return template;
    });
    dispatch(getAvailableTemplatesSuccess(templates));
  }
);

export const getAvailableTemplatesSuccess = data => ({
  type: AT.GET_AVA_TEMPLATES_SUCCESS,
  payload: data
});
