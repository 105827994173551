import React, { Component } from "react";
import { Button, TextInput, Modal } from "../common/";
// import Select from "react-select";
import { Select, Input } from "antd";
import "./stepRulesModa.scss";
import Icon from "@mdi/react";
import { mdiDeleteForever } from "@mdi/js";

class StepRulesRule extends Component {
  constructor(props) {
    super(props);
    this.changeStepNo = this.changeStepNo.bind(this);
    this.changeRule = this.changeRule.bind(this);
    this.changeValue = this.changeValue.bind(this);
    this.changeValueSelect = this.changeValueSelect.bind(this);
    this.delete = this.delete.bind(this);
  }
  changeStepNo(option) {
    console.log("1", option);
    this.props.changeRuleValue(this.props.index, "step", option);
  }
  changeRule(option) {
    this.props.changeRuleValue(this.props.index, "rule", option);
  }
  changeValue(e) {
    this.props.changeRuleValue(this.props.index, "value", e.target.value);
  }
  changeValueSelect(option) {
    console.log("op", option);
    this.props.changeRuleValue(this.props.index, "value", option);
  }
  delete() {
    this.props.deleteRule(this.props.index);
  }

  render() {
    const { stepNo, stepsState } = this.props;
    const { step, rule, value } = this.props.value;

    const stepId = stepsState.stepsOrder[step - 1];
    const stepType = stepsState.steps[stepId]
      ? stepsState.steps[stepId].type.value
      : null;

    let valueInput = null;
    switch (stepType) {
      case "text":
      case "select":
        valueInput = (
          <Input
            disabled={step === null}
            type="text"
            className="c-stepRulesModal__valueInput"
            value={value}
            onChange={this.changeValue}
          />
        );
        break;
      case "rating":
        valueInput = (
          <Select
            options={[
              { value: "1", label: "1 (*)" },
              { value: "2", label: "2 (**)" },
              { value: "3", label: "3 (***)" },
              { value: "4", label: "4 (****)" },
              { value: "5", label: "5 (*****)" }
            ]}
            value={value}
            isDisabled={true}
            isClearable={false}
            className="c-stepRulesModal__valueInput"
            onChange={this.changeValueSelect}
            placeholder={""}
          />
        );
        break;
      case "photo":
      case "number":
        {
          valueInput = (
            <Input
              disabled={step === null}
              type="number"
              className="c-stepRulesModal__valueInput"
              value={value}
              onChange={this.changeValue}
            />
          );
        }
        break;
      case "bool": {
        valueInput = (
          <Select
            options={[
              { value: true, label: "Tak" },
              { value: false, label: "Nie" }
            ]}
            value={value}
            isDisabled={true}
            isClearable={false}
            className="c-stepRulesModal__valueInput"
            onChange={this.changeValueSelect}
            placeholder={""}
          />
        );
        break;
      }
      default:
        valueInput = <div style={{ width: "180px" }} />;
    }

    const availableSteps = Array.from(
      { length: stepNo - 1 },
      (v, k) => k + 1
    ).map(i => ({ value: i.toString(), label: i.toString() }));
    return (
      <div className="c-stepRulesModal__rule">
        <div className="c-stepRulesModal__ruleInputs">
          <span className="c-stepRulesModal__label">Krok</span>
          <Select
            options={availableSteps}
            isClearable={false}
            value={step}
            className="c-stepRulesModal__stepSelect"
            onChange={this.changeStepNo}
            placeholder={""}
          />
          <Select
            options={[
              { value: "=", label: "=" },
              { value: "<", label: "<" },
              { value: ">", label: ">" },
              { value: "<=", label: "<=" },
              { value: ">=", label: ">=" },
              { value: "!=", label: "!=" }
            ]}
            value={rule}
            clearable={false}
            className="c-stepRulesModal__ruleSelect"
            onChange={this.changeRule}
            placeholder={""}
          />
          {valueInput}
          <Icon
            className="c-stepRulesModal__deleteIcon"
            path={mdiDeleteForever}
            size="32px"
            onClick={this.delete}
          />
        </div>
      </div>
    );
  }
}

class StepRulesModal extends Component {
  constructor(props) {
    super(props);
    this.handleInputChange = this.handleInputChange.bind(this);
    this.cancel = this.cancel.bind(this);
    this.save = this.save.bind(this);
    this.resetView = this.resetView.bind(this);
  }

  handleInputChange(event) {
    this.props.changeInputValue("stepRulesModal", event);
  }

  resetView() {
    this.props.resetView("stepRulesModal");
  }
  save() {
    this.props.saveRules();
    this.props.hideModal();
  }
  cancel() {
    this.props.hideModal();
  }

  render() {
    const { rules, stepNo, loading } = this.props.uiState;

    return (
      <Modal
        name="STEP_RULES"
        className="c-stepRulesModal"
        title={"Warunki wyświetlania kroku"}
        cancelText="Anuluj"
        okText={"Zapisz"}
        onCancel={this.cancel}
        onOk={this.save}
        confirmLoading={loading}
        cancelButtonProps={{ disabled: loading }}
        afterClose={this.resetView}
      >
        <div className="c-stepRulesModal__contentWrapper">
          <div className="c-flex c-flex--vertical">
            {rules.map((rule, i) => (
              <StepRulesRule
                key={i}
                index={i}
                value={rule}
                stepNo={stepNo}
                changeRuleValue={this.props.changeRuleValue}
                deleteRule={this.props.deleteRule}
                stepsState={this.props.stepsState}
              />
            ))}
            <div className="c-stepRulesModal__newRuleButtonContainer">
              <span
                className="c-stepRulesModal__newRuleButton"
                onClick={this.props.initNewRule}
              >
                +
              </span>
            </div>
          </div>
        </div>
      </Modal>
    );
  }
}

export default StepRulesModal;
